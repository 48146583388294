import React, { PureComponent } from "react";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";

import { connect, Provider } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
//Defines the look of the context menu
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "font-awesome/css/font-awesome.min.css";

import DatatablesComponent from "./DatatablesComponent";
import { addFlightMenuGufi,
         addRouteInformation,
         notifyTableUpdated,
         openScratchModal,
         removeFlightMenuGufi,
         updateFilter,
         updateOrdering,
         updateTableLength } from "../../actions";
import { ATC_APPROVER_ROLES,
         FO_ROLES,
         SHOW_ME_QUERY,
         IN_SWIM_STATUSES } from "../../constants/Constants";
import { SEARCH_TYPE, SHOW_ME_TABLE_ID } from "../../constants/TableConstants";
import { tosClient } from "../../utils/tosClient";
import { actionUtils } from "../../utils/actionUtils";
import { copySort,
         equalSort,
         makeQueryString, makeSortString } from "../../utils/tableUtils";
import { DATA_TABLE_FILTER_COLUMNS } from "../../column_utils/columnDefaults";
import * as columnFormats from "../../column_utils/columnFormats";
import * as ColumnField from "../../constants/ColumnField";
import FlightMenu from "./FlightMenu";
import { store } from "../../utils/store";
import ConfirmDialog from "../shared/ConfirmDialog";
import userConfig from "../../config/user_config.json";

import "../../css/tables.css";
import { COLUMN_TYPES } from "../../constants/ColumnTypes";
import { hasCustomSort } from "../../column_utils/columnSorting";
import { FoAction } from "../../constants/TosEnum";

require("jquery-contextmenu");

/**
 * This class is used to create and manage a single table.  It enables
 * the various interactive features which are common to multiple table types,
 * such as highlighting on selection and sorting by column.
 */
class Table extends PureComponent
{
    static propTypes = {
        // The table id for this table; passed in from parent
        tableId: PropTypes.string.isRequired,

        // Function to call when removing the table; passed in from parent
        callRemoveTable: PropTypes.func.isRequired,

        // Whether to confirm table removal; passed in from parent
        confirmDelete: PropTypes.bool.isRequired,

        // Optional function to filter the departure flights shown; passed in
        // from parent
        departureFilter: PropTypes.func,

        // Button definitions for the left side of table header; passed in
        // from parent
        leftButtons: PropTypes.func.isRequired,

        // Optional parameter to trigger forced data refresh; passed in from
        // parent
        refresh: PropTypes.bool,

        // dom option for creating table; passed in from parent
        tableDom: PropTypes.string.isRequired,

        // Optional constant table title; passed in from parent
        tableTitle: PropTypes.string,

        // The currently logged in user, from redux
        user: PropTypes.object.isRequired,

        // Flag to indicate if the user can submit route options; from redux
        readOnly: PropTypes.bool.isRequired,

        // The columns selected for this table, from redux
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,

        // Filters defined for the table, from redux
        filters: PropTypes.object,

        // The sorting definition for this table, from redux
        ordering: PropTypes.arrayOf(PropTypes.array),

        // The number of rows per page on the table, from redux
        tableLength: PropTypes.number,

        // The data to display, from redux
        departures: PropTypes.arrayOf(PropTypes.object).isRequired,

        // The flights for which to display a Flight Menu; from redux
        flightMenus: PropTypes.arrayOf(PropTypes.string),

        // Action creator to add a flight menu; from redux
        addFlightMenuGufi: PropTypes.func.isRequired,

        // Function to call to get route information for a flight manu;
        // from redux
        addRouteInformation: PropTypes.func.isRequired,

        // Action creator to notify when the table is finished updating;
        // from redux
        notifyTableUpdated: PropTypes.func.isRequired,

        // Action creator to open the scratch pad modal; from redux
        openScratchModal: PropTypes.func.isRequired,

        // Action creator to remove a flight menu; from redux
        removeFlightMenuGufi: PropTypes.func.isRequired,

        // Action creator to update the filter for this table; from redux
        updateFilter: PropTypes.func.isRequired,

        // Action creator to update the sort for this table; from redux
        updateOrdering: PropTypes.func.isRequired,

        // Action creator to update the number of rows to show for this table;
        // from redux
        updateTableLength: PropTypes.func.isRequired,

        // Navigation; provided by the system
        history: PropTypes.object.isRequired,
    };

    /**
     * Constructs the Table class, which is used to generate a data table with
     * the given options in props.
     *
     * @param {*}      props
     * @param {string} props.tableId          the table id for this table
     * @param {func}   props.callRemoveTable  callback when removing the table
     * @param {bool}   props.confirmDelete    whether to confirm table removal
     * @param {func}   props.departureFilter  (optional) function to filter
     *                                        the departure flights shown
     * @param {func}   props.leftButtons      button definitions for the left
     *                                        side of table header
     * @param {bool}   props.refresh          (optional) flag to trigger
     *                                        forced data refresh
     * @param {string} props.tableDom         DOM options for creating the table
     * @param {string} props.tableTitle       (optional) table title to identify
     *                                        to the user what's in the table
     *                                        (e.g. filter details)
     */
    constructor(props)
    {
        super(props);
        this.state = {
            confirmingDelete: false,
        }

        this.dataTableRef = React.createRef();
        this.allColumns = [];
        this.deleted = false;
        this.openFlightMenus = {};
        this.flightMenuRoots = {};
        this.flightMenuContextMenu = {};

        this.lastFilterSaved = "";
        this.lastLengthSaved = 0;
        this.lastSortSaved = "";

        // Bind these accessors for use in event handlers
        this.updateTableData = this.updateTableData.bind(this);
        this.markDeleted = this.markDeleted.bind(this);
        let checkForChild = this.checkForChild.bind(this);
        let makeTableTitle = this.makeTableTitle.bind(this);
        this.isReadOnly = this.isReadOnly.bind(this);
        let saveProfile = this.saveProfile.bind(this);

        // The tableOptions object is a collection of properties to be passed
        // to the datatable upon creation.
        // NB. Since we use the button to activate searchBuilder, any
        // configuration for that must be added as config for that button, not
        // at the top level.
        // NB2. Because f (search div) uses float right, it must come before
        // the tabName div in the list, even though tabName is between B and f
        // in the actual page layout.
        this.tableOptions = {
            // Set the DOM layout, [B]utton, [f]ind, p[r]ocessing, [t]able,
            // [l]ength, and [p]agination.
            dom: this.props.tableDom,
            /**
             * disable scrollX as it's creating an overlay on the table which requires
             * dataTable.columns.adjust() function call whenever the table width changes.
             * https://datatables.net/forums/discussion/69061/header-does-not-resize-when-using-scrollx
             * 
             * As a workaround, the table is wrapped with a new div element which adds horizontal scrolling.
             * Refer to the componentDidMount lifecycle function in DatatablesComponent.js file. 
             * 
             * This also solves an issue where datatable.columns.adjust() call causes the table header element
             * to scroll into view. 
             */
            scrollX: false,
            paging: true,
            pagingType: 'simple_numbers',

            columnDefs: [{
                targets: "_all",
                defaultContent: "",
                className: "dt-body-nowrap",
                searchable: false,
            }],
            rowId: "gufi",
            buttons: this.props.leftButtons(),
            language: {
                zeroRecords: "No data available in table",
                lengthMenu: "Show _MENU_ flights",
                searchBuilder: {
                    button: "Filter",
                    deleteTitle: "Delete row",
                    leftTitle: "Outdent row",
                    rightTitle: "Indent row",
                    title: 'Use the Indent (>) and Outdent (<) buttons to apply a combination of "and" and "or" qualifiers.',
                    add: "Add Field",
                },  
                paginate: {
                    previous: 'Previous',
                    next: 'Next',
                }
            },
            createdRow: function(row, data, unusedDataIndex) {
                $(row).data({
                    "coordinationStatus": data.coordinationStatus,
                    "eligibilityStatus": data.eligibilityStatus,
                    "tosId": data.tosId,
                    "gufi": data.gufi,
                    "origin": data.origin,
                    "international": data.international,
                });
                // check if we need an open child (Flight Menu) for this gufi
                checkForChild(row, data.gufi, this.api());
                $(row).addClass("flight-table");
            },
            drawCallback: function(unusedSettings) {
                makeTableTitle();
            },
            // We really just use half of the built-in state saving. Their
            // loading will end if it sees that the number of columns has
            // changed. But the saving is good for getting the current
            // configuration, which we pull off when we need it and use
            // redux to store and load things from there.
            stateSave: true,
            stateSaveCallback: function(unusedSettings, data) {
                saveProfile(data);
            },
            stateLoadCallback: function(unusedSettings) {
                // Don't load from the saved state, so don't bother loading
                return {};
            },
            stateLoadParams: function(unusedSettings, unusedData) {
                // Don't load from the saved state, use what was set in options
                return false;
            },
        };

        if (this.props.tableLength)
        {
            this.tableOptions.pageLength = this.props.tableLength;
        }

        this.updateColumns();
        if (this.props.departureFilter)
        {
            this.departures = this.props.departureFilter(this.props.departures);
        }
        else
        {
            this.departures = this.props.departures;
        }
    }

    /**
     * Checks if we want a flight menu open for a row, and opens one up if
     * we do. If we have one already created, use that. Otherwise makes a
     * new flight menu.
     *
     * @param {object} row  datatable row
     * @param {string} gufi gufi for flight in this row
     * @param {object} api  datatables api for this table
     */
    checkForChild(row, gufi, api)
    {
        if (this.props.flightMenus.includes(gufi))
        {
            if (this.openFlightMenus[gufi])
            {
                // put back the child from before the refresh
                api.row(row).child(this.openFlightMenus[gufi]).show();
                // put back the context menu because the context menu is not being defined
                // for the flights that are not visible on the current page of the table.
                if (this.flightMenuContextMenu[gufi]) {
                    $(api.row(row).child()[0]).contextMenu(this.flightMenuContextMenu[gufi]);
                }
            }
            else
            {
                let closeRouteMenu = this.closeFlightMenu.bind(this);
                // open the route options menu by creating an empty span in a
                // table cell, then using React to render the Flight Menu
                // component there, passing along the common redux store.
                // In other words, this is doing the React rendering of the
                // Flight Menu inside the non-React data table, inside the
                // React main app, using the same redux store that we
                // use everywhere else
                api.row(row).child(`<span class="innerTable" />`).show();

                const root = createRoot(api.row(row).child().find("span").get(0));
                root.render(
                    <Provider store={store}>
                        <FlightMenu key={gufi} gufi={gufi}
                            handleContextMenuUpdate={this.handleFlightMenuContextMenuUpdate}
                            tableGroupId={this.props.tableId}
                            colSelection={this.gotoColSelection}
                            onClose={closeRouteMenu}
                        />
                    </Provider>
                );
                this.flightMenuRoots[gufi] = root;
            }
            $(row).addClass("shown");
        }
    }

    /**
     * Creates the buttons associated with this table aligned on the top right.
     * Datatables.net allows multiple sets of buttons, but only one can be
     * created in the table initialization, so this set has to be created and
     * added directly.
     */
    createButtonsRight()
    {
        let urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has(SHOW_ME_QUERY) ||
           (this.props.tableId !== SHOW_ME_TABLE_ID))
        {
            const confirmDelete = this.props.confirmDelete;

            let buttons2 = new
              $.fn.dataTable.Buttons(this.dataTableRef.current.dataTable, {
                buttons: [
                    {
                        text: "Remove",
                        action: (unusedEvent, unusedDt, unusedNode,
                                     unusedCfg) => {
                            if (confirmDelete)
                            {
                                this.setState({ confirmingDelete: true });
                            }
                            else
                            {
                                this.confirmTableDelete(true);
                            }
                        },
                        titleAttr: "Remove this table",
                    }
                ],
                dom: {
                    container: {
                        className: "buttons-right",
                    }
                },
            });

            // Put this set after the first set; this adds the right button
            // container after the left button container, but the css will float
            // it right.  The search panel also floats right, so the right
            // buttons need to be defined in the html before that so that it
            // floats more-right.
            this.dataTableRef.current.dataTable.table().buttons(0, null)
                .container().after(buttons2.container());
        }
    }

    /**
     * Callback function for confirming table deletion. If confirmed, removes
     * this table.
     */
    confirmTableDelete = (confirmed) =>
    {
        this.setState({ confirmingDelete: false });
        if (confirmed)
        {
            this.markDeleted();
            this.props.callRemoveTable(this.props.tableId);
        }
    }
    
    /**
     * Store the mappings between flights and their corresponding flight menu's context menu.
     * 
     * @param {string} gufi gufi for flight 
     * @param {object} contextMenu context menu associated with flight
     */
    handleFlightMenuContextMenuUpdate = (gufi, contextMenu) => {
        this.flightMenuContextMenu[gufi] = contextMenu;
    }

    /**
     * Marks this table as having been deleted on purpose.
     */
    markDeleted()
    {
        this.deleted = true;
    }

    /**
     * Saves the settings information into redux.
     */
    saveProfile(data)
    {
        // keep updating redux until the filter is stable. Datatables
        // calls this after adding each section of the filter.
        let currentFilter = makeQueryString(data.searchBuilder);
        if (this.lastFilterSaved !== currentFilter)
        {
            this.lastFilterSaved = currentFilter;
            this.props.updateFilter(this.props.tableId, data.searchBuilder);
        }

        if (this.props.tableLength !== data.length)
        {
            if (this.lastLengthSaved !== data.length)
            {
                this.lastLengthSaved = data.length;
                this.props.updateTableLength(this.props.tableId, data.length);
            }
        }
        if (!equalSort(this.props.ordering, data.order))
        {
            let currentSort = makeSortString(data.order);
            if (this.lastSortSaved !== currentSort)
            {
                this.lastSortSaved = currentSort;
                this.props.updateOrdering(this.props.tableId, data.order);
            }
        }
    }

    /**
     * Used to pass new data to the table. Maintains horizontal scroll and any
     * open child flight menus.
     */
    updateTableData()
    {
        let dataTable = this.dataTableRef.current.dataTable;

        // an open flight menu messes with the scroll position on refresh, so
        // we need to manually save and restore it. Vertical scrolling is done
        // the whole page via TablesManager, horizontal scrolling is per table.
        let scrollContainer = $(dataTable.table().node())
            .parent("div.dataTables_scrollBody");
        let scrollLeft = scrollContainer.scrollLeft();
        let tableManagerScrollTop = $(".main-frame").scrollTop();

        // store off any open flight menus to restore during refresh
        let openFlightMenus = this.openFlightMenus;

        // ignore bad warning about Array.every(), this is dataTable every
        /* eslint-disable-next-line */
        dataTable.rows(".shown").every(function(rowIdx, tableLoop, rowLoop) {
            // "this" is the opened row
            openFlightMenus[this.id()] = this.child();
        });

        // clean up the flight menu's context menu
        let openFlightMenuKeySet = new Set(Object.keys(openFlightMenus));
        this.flightMenuContextMenu && Object.keys(this.flightMenuContextMenu).forEach(gufi => {
            if (!openFlightMenuKeySet.has(gufi)) {
                delete this.flightMenuContextMenu[gufi];
            }
        })

        this.dataTableRef.current.reloadTableData(this.departures);
        $(".main-frame").scrollTop(tableManagerScrollTop);
        scrollContainer.scrollLeft(scrollLeft);
        this.props.notifyTableUpdated(this.props.tableId);
    }

    /**
     * If this react component has been created, then set up the context menu
     * and add any saved filters.
     */
    componentDidMount()
    {
        let contextMenuBuilder = this.createContextMenuBuilder();

        $("#" + this.props.tableId).contextMenu({
                selector: "tr",
                trigger: "right",
                build: contextMenuBuilder,
                className: "contextMenuMainTable",
            });

        // load any stored filters
        if (this.props.filters)
        {
            this.dataTableRef.current.setFilterDetails(this.props.filters);
        }
        this.makeTableTitle();

        // the second button set needs to be created after the table has been
        // defined, so we do it now.
        this.createButtonsRight();
        this.addFlightMenuOpener();
        this.addScratchEditorModalOpener();
    }

    /**
     * Creates the right-click context menu for the departure table.
     * Flight Operators have a menu to exclude a flight. FO and ATC could
     * have actions based on flight coordination status.
     */
    createContextMenuBuilder()
    {
        let user = this.props.user;
        let readOnly = this.isReadOnly;
        let operator = FO_ROLES.includes(user.role);
        let controller = ATC_APPROVER_ROLES.includes(user.role);
        let getOperatorOptions = this.getOperatorOptions.bind(this);
        let getAtcOptions = this.getAtcOptions.bind(this);

        let buildFunc = function($tr, unusedEvent) {
            let fltTosId = $tr.data("tosId");
            let fltGufi = $tr.data("gufi");
            let fltOrigin = $tr.data("origin");
            let fltEligStatus = $tr.data("eligibilityStatus");
            let fltCoordStatus = $tr.data("coordinationStatus");
            let international = $tr.data("international");

            let itemList = {};

            // No context menu in read-only mode
            if (readOnly())
            {
                itemList.filler = {
                    name: "Route options unavailable",
                    disabled: true,
                };
            }
            // No context menu if no flight.  This removes redundant
            // error checking below
            else if (!fltTosId)
            {
                itemList.filler = {
                    name: "Missing flight data",
                    disabled: true,
                };
            }
            // Flight operator options
            else if (operator)
            {
                // Route options
                itemList.operatorAction = {
                    name: "Route Action",
                    icon: "loading",
                    items: getOperatorOptions(user, fltGufi, fltTosId,
                        fltEligStatus, fltCoordStatus, international),
                };

/* Flight Operator exclusion is not really working out, but leaving code
   commented out in place in case it comes back */
                // Exclude
/*                itemList.sep1 = "------";
                itemList.exclude = {
                    name: "Exclude Flight",
                    callback: function(unusedKey, unusedOptions) {
                        tosClient.postOperatorUpdate(user.role, user.roleUser,
                            fltTosId, "", "EXCLUDE_TOS_FLIGHT");
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return international || !actionUtils.enableOperatorExcluded(
                                   fltEligStatus, fltCoordStatus);
                    },
                };

                // Undo Exclude
                itemList.unexclude = {
                    name: "Undo Exclude Flight",
                    callback: function(unusedKey, unusedOptions) {
                        tosClient.postOperatorUpdate(user.role, user.roleUser,
                            fltTosId, "", "UNEXCLUDE_TOS_FLIGHT");
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return international || !actionUtils.enableOperatorUnexcluded(
                                   fltEligStatus, fltCoordStatus);
                    },
                };
*/
            }
            // Controller with approval authority
            else if (controller &&
                     actionUtils.hasAtcAuthority(user.role, fltOrigin))
            {
                itemList.atcAction = {
                    name: "Route Action",
                    icon: "loading",
                    items: getAtcOptions(user, fltGufi, fltTosId,
                               fltEligStatus, fltCoordStatus, international),
                };
            }
            // Controller without approval authority
            else if (controller)
            {
                itemList.atcAction = {
                    name: "No ATC authority for Origin=" + fltOrigin,
                    disabled: true,
                };
            }
            // Should never get here
            else
            {
                itemList.filler = {
                    name: "No actionable route options",
                    disabled: true,
                };
            }

            return {
                items: itemList,
                autoHide: true,
            };
        };

        return buildFunc;
    }

    /**
     * Submits the requested option as the only TOS option to SWIM.
     *
     * @param {object} user               current logged-in user
     * @param {string} tosId              TOS identifier
     * @param {string} optionId           name of chosen option
     * @param {array}  routeOptionList    list of options in this TOS
     */
    submitOption(user, tosId, optionId, routeOptionList)
    {
        const actionArray = [];
        routeOptionList.forEach(option => {
            if (option.id === optionId)
            {
                if (!IN_SWIM_STATUSES.includes(option.swimStatus))
                {
                    actionArray.push({
                        routeId: option.id,
                        action: FoAction.SAVE_SWIM.name,
                    });
                }
            }
            else
            {
                if (IN_SWIM_STATUSES.includes(option.swimStatus))
                    {
                        actionArray.push({
                            routeId: option.id,
                            action: FoAction.CANCEL_SWIM.name,
                        });
                    }
                }
        });
        tosClient.postOperatorArrayUpdate(user.role, user.roleUser, tosId, actionArray);
    }

    /**
     * Gets the flight operator's options for the right click menu.
     *
     * @param {object} user               current logged-in user
     * @param {string} gufi               flight identifier
     * @param {string} tosId              TOS identifier
     * @param {string} flightEligStatus   flight-level eligibility status
     * @param {string} flightCoordStatus  flight-level coordination status
     * @param {boolean} international     if flight is international
     *
     * @return {object} menu of route actions, each with its own submenu of
     *                  route options
     */
    async getOperatorOptions(user, gufi, tosId, flightEligStatus,
       flightCoordStatus, international)
    {
        let optionList = {};
        let postSwimOption = this.submitOption;

        let flightParams = {
            role: user.role,
            carrier: userConfig[user.roleUser].carrierForRequests,
            gufiList: [ gufi ],
        };

        let routesList = await tosClient.getFlightRoutes(flightParams);
        if (routesList)
        {
            let submitList = {};
            let undoSubmitList = {};

            // Pull out just the route options (i.e. exclude the filed route)
            let routeOptionsList = [];
            routesList.forEach((record) => {
                if ( !(record.isFiledRoute || record.isInitialRoute) )
                {
                    routeOptionsList.push(record);
                }
            });

            // Now create the sub-menu options for each route
            let hasSubmit = false;
            let hasUndoSubmit = false;
            if (!international) {
              routeOptionsList.forEach((record) => {
                  let id = record.id;
                  let routeDataStr = this.formatOptionLine(record);

                  if (actionUtils.enableSubmit(flightEligStatus,
                          flightCoordStatus, record.coordinationStatus))
                  {
                      hasSubmit = true;
                      submitList[id] = {
                          name: routeDataStr,
                          className: "routeOption",
                          callback: function(unusedKey, unusedOptions) {
                              console.log("Request Reroute " + tosId + " " + id);
                              tosClient.postOperatorUpdate(user.role,
                                  user.roleUser, tosId, id,
                                  FoAction.REQUEST_TOS_ROUTE.name, gufi)
                              postSwimOption(user, tosId, id, routeOptionsList);
                          },
                      };
                  }

                  if (actionUtils.enableUnsubmit(flightCoordStatus,
                          record.coordinationStatus))
                  {
                      hasUndoSubmit = true;
                      undoSubmitList[id] = {
                          name: routeDataStr,
                          className: "routeOption",
                          callback: function(unusedKey, unusedOptions) {
                              console.log("Undo Request Reroute " + tosId + " " + id);
                              tosClient.postOperatorUpdate(user.role,
                                  user.roleUser, tosId, id,
                                  "CANCEL_REQUEST_TOS_ROUTE", gufi)
                          },
                      };
                  }
              });
            }

            optionList = {
                submit: {
                    name: "Request Reroute",
                    disabled: !hasSubmit,
                    items: submitList,
                },
                unsubmit: {
                    name: "Undo Request Reroute",
                    disabled: !hasUndoSubmit,
                    items: undoSubmitList,
                },
            };
        }

        return optionList;
    }

    /**
     * Gets the controller's options for the right click menu.  This assumes
     * that it was already verified that ATC has authority for route actions.
     *
     * @param {object} user               current logged-in user
     * @param {string} gufi               flight identifier
     * @param {string} tosId              TOS identifier
     * @param {string} flightEligStatus   flight-level eligibility status
     * @param {string} flightCoordStatus  flight-level coordination status
     * @param {boolean} international     if flight is international
     *
     * @return {object} menu of route actions, each with its own submenu of
     *                  route options
     */
    async getAtcOptions(user, gufi, tosId, flightEligStatus, flightCoordStatus, international)
    {
        let optionList = {};

        let flightParams = {
            role: user.role,
            carrier: userConfig[this.props.user.roleUser].carrierForRequests,
            gufiList: [ gufi ],
        };

        let routesList = await tosClient.getFlightRoutes(flightParams);
        if (routesList)
        {
            let approveList = {};
            let undoApproveList = {};
            let unableList = {};
            let undoUnableList = {};

            // Pull out just the route options (i.e. exclude the filed route)
            let routeOptionsList = [];
            routesList.forEach((record) => {
                if ( !(record.isFiledRoute || record.isInitialRoute) )
                {
                    routeOptionsList.push(record);
                }
            });

            // These flags will be used to set the enabled/disabled status
            let hasApprove = false;
            let hasUndoApprove = false;
            let hasUnable = false;
            let hasUndoUnable = false;

            // Now create the sub-menu options for each route
            if (!international)
            {
                routeOptionsList.forEach((record) => {
                    let id = record.id;
                    let routeDataStr = this.formatOptionLine(record);

                    if (actionUtils.enableApprove(
                            flightCoordStatus, record.coordinationStatus))
                    {
                        hasApprove = true;
                        approveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Approve " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    "APPROVE_TOS_ROUTE", gufi)
                            },
                        }
                    }

                    if (actionUtils.enableUnapprove(
                            flightCoordStatus, record.coordinationStatus))
                    {
                        hasUndoApprove = true;
                        undoApproveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Undo Approve " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    "UNAPPROVE_TOS_ROUTE", gufi)
                            },
                        }
                    }

                    if (actionUtils.enableUnable(
                            flightCoordStatus, record.coordinationStatus))
                    {
                        hasUnable = true;
                        unableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Unable " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    "UNABLE_TOS_ROUTE", gufi)
                            },
                        }
                    }

                    if (actionUtils.enableUnunable(
                            flightCoordStatus, record.coordinationStatus))
                    {
                        hasUndoUnable = true;
                        undoUnableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Undo Unable " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    "UNUNABLE_TOS_ROUTE", gufi)
                            },
                        }
                    }
                });
            }
            optionList = {
                approve: {
                    name: "Approve",
                    disabled: !hasApprove,
                    items: approveList,
                },
                unapprove: {
                    name: "Undo Approve",
                    disabled: !hasUndoApprove,
                    items: undoApproveList,
                },
                filler: "----",
                unable: {
                    name: "Unable",
                    disabled: !hasUnable,
                    items: unableList,
                },
                ununable: {
                    name: "Undo Unable",
                    disabled: !hasUndoUnable,
                    items: undoUnableList,
                },
            };
        }

        return optionList;
    }

    /**
     * Parses out the route option record to create right click menu option
     * line.
     *
     * @param {object} record  route option data
     *
     * @return formatted line
     */
    formatOptionLine(record)
    {
        let routeDataStr = "";

        if ( !(record.isFiledRoute || record.isInitialRoute) )
        {
            routeDataStr = record.id + " " +
                columnFormats.formatEligibilityState(
                    record.eligibilityStatus, "display") +
                " ETOT=" +
                columnFormats.formatDateTimeHHMM(
                    record.estimatedTakeOffTime, "display") +
                " Delay Savings=" +
                columnFormats.formatNumberWithSign(
                    record.offDelaySavings, "display");
        }

        return routeDataStr;
    }

    /**
     * Uses react history to go to the column selection page for the given table.
     *
     * @param {string} tableId  id of table for columns
     */
    gotoColSelection = (tableId) =>
    {
        // Go to column selection page
        this.props.history.push({ pathname: "/columnSelection",
                       tableId: tableId });
    };

    /**
     * Accessor for the readOnly property to reach it from handlers. Indicates if actions
     * such as excluding a flight are allowed.
     */
    isReadOnly()
    {
        return this.props.readOnly || this.props.user.readOnly;
    }

    /**
     * Adds the listener to open the flight menu when the trigger field is
     * clicked.
     */
    addFlightMenuOpener()
    {
        // Maximum number of data table columns that the Route Menu will span
        const MAX_RM_COL_SPAN = 14;

        let dataTable = this.dataTableRef.current.dataTable;
        let addGufi = this.props.addFlightMenuGufi;
        let addRoutes = this.props.addRouteInformation;
        let tableId = this.props.tableId;
        let user = this.props.user;
        let colSelection = this.gotoColSelection;
        let closeFM = this.closeFlightMenu.bind(this);
        let numCols = this.props.columns.length;
        let flightMenuRoots = this.flightMenuRoots;

        let flightMenuEventHandler = function()
        {
            let tr = $(this).closest("tr");
            let row = dataTable.row(tr);
            let gufi = row.data().gufi;

            if (row.child.isShown())
            {
                // if the fm is open, close it
                closeFM(gufi);
            }
            else
            {
                addRoutes(user.role, userConfig[user.roleUser].carrierForRequests, gufi);
                addGufi(tableId, gufi);

                // Open the flight menu by creating an empty span in a
                // table cell, then using React to render the Flight Menu
                // component there.
                row.child(`<span class="innerTable" />`).show();

                // The library code will set "colspan=<all columns>" for this
                // child row, which means the route menu will be excessivly
                // wide.  Reduce the number of columns the route menu will
                // span; the data table columns will most likely end up
                // stretching, but the route menu will look better.
                let data = $(row.child()).children(":first-child");
                if ($(data).attr("colspan"))
                {
                    if (numCols < MAX_RM_COL_SPAN)
                    {
                        $(data).attr("colspan", numCols);
                    }
                    else
                    {
                        $(data).attr("colspan", MAX_RM_COL_SPAN);
                    }
                }

                const root = createRoot(row.child().find("span").get(0));
                root.render(
                    <Provider store={store}>
                        <FlightMenu key={gufi} gufi={gufi}
                            tableGroupId={tableId}
                            colSelection={colSelection}
                            onClose={closeFM}
                        />
                    </Provider>,
                );
                flightMenuRoots[gufi] = root;
                tr.addClass("shown");
            }
        }

        $("#" + this.props.tableId + " tbody").on("click", "tr.flight-table td.fm-trigger", flightMenuEventHandler);
        $("#" + this.props.tableId + " tbody").on("dblclick", "tr.flight-table td", flightMenuEventHandler);
    }

    /**
     * Adds the listener to open the editor modal when the input field is
     * clicked.
     */
    addScratchEditorModalOpener()
    {
        let dataTable = this.dataTableRef.current.dataTable;
        let openScratchEditorModal = this.props.openScratchModal;

        $("#" + this.props.tableId + " tbody").on("click", "input.scratch",
            function(unusedEvent) {
                let tr = $(this).closest("tr");
                let row = dataTable.row(tr);
                let gufi = row.data().gufi;
                let acid = row.data().acid;
                let scratchPad = row.data().scratchPad;

                // open the editor with the current scratch pad value
                openScratchEditorModal(gufi, acid, scratchPad);
            });
    }

    /**
     * Close the flight menu in this table for the given gufi. This includes
     * hiding the child row, unmounting the flight menu from React, and
     * removing the gufi from the list in redux.
     *
     * @param {string} gufi   flight identifier of flight menu to close
     */
    closeFlightMenu(gufi)
    {
        let row = this.dataTableRef.current.dataTable.row("#" + gufi);
        row.child.hide();
        $(row.node()).removeClass("shown");
        this.unmountFlightMenu(gufi);
        this.props.removeFlightMenuGufi(this.props.tableId, gufi);
        delete this.flightMenuContextMenu[gufi]
    }

    /**
     * If this component did update, then make the appropriate changes to
     * the table: update table data, update columns, clear out cached flight
     * menus, update the filter label.
     *
     * @param {object} prevProps   the props object before the update
     */
    componentDidUpdate(prevProps)
    {
        if (this.props.refresh && !prevProps.refresh &&
            this.props.departureFilter)
        {
            this.departures = this.props.departureFilter(this.props.departures);
            this.updateTableData();
        }
        // Need to refresh the table when departure info changes or when the
        // list of open flight menus changes.
        if ((this.props.departures !== prevProps.departures) ||
            (this.props.flightMenus !== prevProps.flightMenus))
        {
            if (this.props.departureFilter)
            {
                this.departures = this.props.departureFilter(this.props.departures);
            }
            else
            {
                this.departures = this.props.departures;
            }

            if (this.props.flightMenus && this.props.flightMenus.length)
            {
                // if the departure goes away when we have an open flight menu,
                // close the flight menu.
                this.props.flightMenus.forEach(gufi => {
                    if (!this.departures.find(depRec => {
                        return (gufi === depRec.gufi);
                    }))
                    {
                        this.unmountFlightMenu(gufi);
                        this.props.removeFlightMenuGufi(this.props.tableId, gufi);
                        delete this.flightMenuContextMenu[gufi];
                    }
                });
            }

            this.updateTableData();
        }

        if (this.props.columns !== prevProps.columns)
        {
            this.updateColumns();
        }

        if (this.props.flightMenus !== prevProps.flightMenus)
        {
            // clear out cached flight menus from previous data refreshes
            // when the flight menu is closed
            for (const openMenu in this.openFlightMenus)
            {
                if (!this.props.flightMenus.includes(openMenu))
                {
                    delete this.openFlightMenus[openMenu];
                    delete this.flightMenuContextMenu[openMenu];
                }
            }
        }
    }

    /**
     * Converts the saved list of columns to display to the column definitions
     * for Datatables. Adds other "filterable" columns that are not being
     * shown as hidden fields. Updates all columns to use "filter2" type to
     * retrieve their search value, to avoid being limited by "searchable"
     * attributes of the general search function. Updates sorting to match
     * new column order.
     */
    updateColumns()
    {
        let filterColumns = [ ...DATA_TABLE_FILTER_COLUMNS ];
        let allColumns = [];

        this.props.columns.forEach(viewedColumnName => {
            let viewedColumn = ColumnField.getColumnByValue(viewedColumnName);

            // Use the default sorting logic (empty string at the bottom of the page)
            // if the column type does not have its own custom sorting logic.
            // The custom sorting logics are added through the addOrdering() function
            // in src/column_utils/columnSorting.js
            let columnType = viewedColumn.type;
            if (!hasCustomSort(viewedColumn.type)) {
                columnType = COLUMN_TYPES.NON_EMPTY_STRING;
            }

            allColumns.push({
                ...viewedColumn,
                type: columnType,                       // type used for sorting.
                searchBuilderType: viewedColumn.type,   // type used for search filter.
                searchBuilder: {
                    orthogonal: {
                        search: SEARCH_TYPE,
                    },
                },
            });
            let index = filterColumns.indexOf(viewedColumn);
            if (index > -1)
            {
                filterColumns.splice(index, 1);
            }
        });

        filterColumns.forEach(filterColumn => {
            if ((filterColumn.disabled === undefined) || !filterColumn.disabled)
            {
                allColumns.push({
                    ...filterColumn,
                    visible: false,
                    searchBuilder: {
                        orthogonal: {
                            search: SEARCH_TYPE,
                        },
                    },
                });
            }
        });

        this.allColumns = allColumns;

        // update sort to match these columns
        if (this.props.ordering && this.props.ordering.length)
        {
            this.tableOptions.order = copySort(this.props.ordering);
        }
    }

    /**
     * Makes and updates the title of the table based on current filtering.
     */
    makeTableTitle()
    {
        if (this.props.tableTitle && this.props.tableTitle.length)
        {
            $("." + this.props.tableId + " div.tabName").html(this.props.tableTitle).addClass("given");
        }
        // Current table reference; if unset, the table hasn't been
        // completely defined yet.
        else if (this.dataTableRef.current)
        {
            let filters = this.dataTableRef.current.getFilterDetails();
            let tableTitle = "Filter: " + makeQueryString(filters);
            $("." + this.props.tableId + " div.tabName").html(tableTitle);
        }
    }

    /**
     * If this table will be removed, unmount any child flight menus.
     */
    componentWillUnmount()
    {
        Object.values(this.flightMenuRoots).forEach(root => root.unmount());
    }

    /**
     * Unmounts the react component containing the flight menu.
     */
    unmountFlightMenu(gufi)
    {
        if (this.flightMenuRoots[gufi])
        {
          this.flightMenuRoots[gufi].unmount();
        }
    }

    /**
     * Set up the table wrapper for rendering.
     *
     * @return {JSX.element} The container/wrapper for the formatted table
     */
    render()
    {
       // Note: ref in DatatablesComponent initializes dataTableRef with access
       // to this DatatablesComponent instance.
        return (
            <div>
                <DatatablesComponent
                    ref={this.dataTableRef}
                    columns={this.allColumns}
                    data={this.departures}
                    tableOptions={this.tableOptions}
                    tableId={this.props.tableId}
                />
                <ConfirmDialog isShown={this.state.confirmingDelete}
                    onSelection={this.confirmTableDelete}
                >
                    <p>Are you sure you want to remove this TOS Table?</p>
                </ConfirmDialog>
            </div>
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state, ownProps) =>
{
    return {
        user: state.authentication.user,

        readOnly: state.dataReducer.readOnly,

        columns: state.columnsReducer.columnsPerTable[ownProps.tableId],
        filters: state.tablesReducer.filterPerTable[ownProps.tableId],
        ordering: state.columnsReducer.orderPerTable[ownProps.tableId],
        tableLength: state.tablesReducer.lengthPerTable[ownProps.tableId],

        departures: state.dataReducer.departures,
        flightMenus: state.flightMenuReducer.flightGufis[ownProps.tableId],
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addFlightMenuGufi,
    addRouteInformation,
    notifyTableUpdated,
    openScratchModal,
    removeFlightMenuGufi,
    updateFilter,
    updateOrdering,
    updateTableLength,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
