import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";

import { openLogoutModal, openSettingsModal } from "tos-web-admin-client";

import { openAlertTypesModal,
         openColorAlertsModal,
         openHotlineModal,
         updateGlobalSearch } from "./actions";
import { ALERT_TYPES_SETTINGS,
         COLOR_ALERTS_SETTINGS,
         HELP,
         HOTLINE,
         LOAD_SAVE_SETTINGS,
         LOGOUT_MENUITEM,
         TITLE,
         USER_SETTINGS } from "./constants/NavBarConstants";
import { DIP_ENVIRONMENT } from "./constants/Constants";
import DataTimeDisplay from "./components/header/DataTimeDisplay";
import TosModeDisplay from "./components/header/TosModeDisplay";
import logo from "./images/meatball.jpg";
import "./css/navbar.css";

/**
 * Creates the main title and navigation bar for the TOS tables.
 */
class PageHeader extends Component
{
    static propTypes = {
        // Flag for whether the update was sucessful; passed in by App
        wasUpdateSuccessful: PropTypes.bool,

        // The update error text, may be blank; passed in by App
        errorText: PropTypes.string,

        // True to only disply the application title; passed in by App
        titleOnly: PropTypes.bool,

        // Flag if the user is logged in; from Redux
        loggedIn: PropTypes.bool.isRequired,

        // Current user name; from Redux
        userName: PropTypes.string.isRequired,

        // User-entered global search value; from Redux
        globalSearch: PropTypes.string.isRequired,

        // Flag if the user-entered global search value is blank; from Redux
        emptySearch: PropTypes.bool.isRequired,

        // Action creator to open the alert types dialog; from Redux
        openAlertTypesModal: PropTypes.func,

        // Action creator to open the color alerts dialog; from Redux
        openColorAlertsModal: PropTypes.func,

        // Action creator to open the hotline dialog; from Redux
        openHotlineModal: PropTypes.func,

        // Action creator to open the logout dialog; from Redux
        openLogoutModal: PropTypes.func,

        // Action creator to open the settings dialog; from Redux
        openSettingsModal: PropTypes.func,

        // Action creator to update the text in the global search; from Redux
        updateGlobalSearch: PropTypes.func,
    };

    /**
     * Processes the Alert Types click by opening the Alert Types Modal.
     */
    processAlertTypesClick = () =>
    {
        this.props.openAlertTypesModal();
    };

    /**
     * Processes the Color Alerts click by opening the Color Alerts Modal.
     */
    processColorAlertsClick = () =>
    {
        this.props.openColorAlertsModal();
    };

    /**
     * Processes the Help click by opening the Help page in a new tab.
     */
    processHelpClick = () =>
    {
        let url = window.location.origin + "/help";
        window.open(url, '_blank');
    };

    /**
     * Processes the Hotline click by opening the Hotline Modal.
     */
    processHotlineClick = () =>
    {
        this.props.openHotlineModal();
    };

    /**
     * Processes the Load/Save click by opening the Settings Modal.
     */
    processLoadSaveClick = () =>
    {
        this.props.openSettingsModal();
    };

    /**
     * Processes the Log Out click by opening the Logout Modal.
     */
    processLogoutClick = () =>
    {
        this.props.openLogoutModal();
    };

    /**
     * Updates the global search value for the Show Me table.
     */
    processGlobalSearch = (ev) =>
    {
        this.props.updateGlobalSearch(ev.target.value.toUpperCase());
    }

    /**
     * If this component did update, then check the props to see if the
     * global search field needs to be cleared, such as when the Show Me
     * table is removed.
     *
     * @param {object} prevProps The props object before the update
     */
    componentDidUpdate(prevProps)
    {
        if ((this.props.globalSearch === "") && (prevProps.globalSearch !== ""))
        {
            this.globalSearchInput.value = "";
        }
    }

    /**
     * Sets up the nav bar at the top of the page for rendering.
     *
     * @return {JSX.element} The nav bar
     */
    render()
    {
        let showFullHeader = false;
        if (this.props.loggedIn && !this.props.titleOnly)
        {
            showFullHeader = true;
        }

        let title = TITLE;
        let navbarCssClass = "navbar";
        if( DIP_ENVIRONMENT === "dev" )
        {
            title = title + " - DEV";
            navbarCssClass = "navbar-dev";
        }
        if( DIP_ENVIRONMENT === "stage" )
        {
            title = title + " - STAGE";
            navbarCssClass = "navbar-stage";
        }

        if (showFullHeader)
        {
            const searchClass =
                "search" + (this.props.emptySearch ? " empty" : "");

            return (
                <Navbar variant="dark" expand="md" className={navbarCssClass}>
                    <Container>
                        <Navbar.Brand>
                            <img
                                alt=""
                                src={logo}
                                width="31"
                                height="26"
                                className="d-inline align-middle me-2"
                            />
                            {title}
                        </Navbar.Brand>
                    </Container>
                    <Container className="justify-content-center">
                        <DataTimeDisplay
                            wasUpdateSuccessful={this.props.wasUpdateSuccessful}
                            errorText={this.props.errorText} />
                    </Container>
                    <Form.Control type="text" placeholder="search"
                        aria-label="Search" className={searchClass}
                        defaultValue={this.props.globalSearch}
                        htmlSize="10" onChange={this.processGlobalSearch}
                        ref={input => this.globalSearchInput = input}
                    />
                    <TosModeDisplay />
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <NavDropdown title={USER_SETTINGS} >
                            <NavDropdown.Item
                                eventKey="alert-type"
                                onClick={this.processAlertTypesClick}
                            >
                                {ALERT_TYPES_SETTINGS}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="color-alerts"
                                onClick={this.processColorAlertsClick}
                            >
                                {COLOR_ALERTS_SETTINGS}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={HELP} >
                            <NavDropdown.Item
                                eventKey="help"
                                onClick={this.processHelpClick}
                            >
                                {HELP}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="hotline"
                                onClick={this.processHotlineClick}
                            >
                                {HOTLINE}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={this.props.userName.toUpperCase()}
                        >
                            <NavDropdown.Item
                                eventKey="load-save"
                                onClick={this.processLoadSaveClick}
                            >
                                {LOAD_SAVE_SETTINGS}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                eventKey="logout"
                                onClick={this.processLogoutClick}
                            >
                                {LOGOUT_MENUITEM}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
            );
        }
        else
        {
            return (
                <Navbar variant="dark" expand="lg" className={navbarCssClass}>
                    <Container>
                        <Navbar.Brand>
                            <img
                                alt=""
                                src={logo}
                                width="31"
                                height="26"
                                className="d-inline align-middle me-2"
                            />
                            {title}
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            );
        }
    }
}

/**
 * Maps the Redux state to local props for access.
 *
 * @param {Object} state  full Redux state
 */
function mapStateToProps(state)
{
    let userName = "";
    const { loggedIn, user } = state.authentication;
    if (loggedIn)
    {
        userName = user.username;
    }

    return {
        loggedIn,
        userName,
        globalSearch: state.showMeReducer.globalSearch,
        emptySearch: state.showMeReducer.emptySearch,
    };
}

const mapDispatchToProps = {
    openAlertTypesModal,
    openColorAlertsModal,
    openHotlineModal,
    openLogoutModal,
    openSettingsModal,
    updateGlobalSearch
}

export default connect(mapStateToProps, mapDispatchToProps) (PageHeader);
