import React from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FlashyAlertModal from "./FlashyAlertModal";
import { addShowMeFlights } from "../../actions";
import { getColorForEnum } from "../../column_utils/columnColors";
import { COLUMNS } from "../../constants/ColumnField";
import { ALERT_TYPE_COORDINATION,
         ALERT_TYPE_FILTER,
         ALERT_TYPE_SCRATCH_PAD } from "../../constants/Constants";
import { BLACK_CSS,
         WHITE_CSS } from "../../constants/ColorAlertConstants";
import { CoordinationState } from "../../constants/TosEnum";
import coordinationSoundFile from "../../sounds/AlertSounds.wav";
import filterFlightSoundFile from "../../sounds/ka-ching.mp3";
import scratchPadSoundFile from "../../sounds/RingyDing.wav";

/**
 * Serves as a modal dialog for alerting the user about flight routes
 * being submitted or approved.
 */
class FlightAlertModal extends FlashyAlertModal
{
    static propTypes = {
        // The callback function to indicate acknowledgement; passed in
        onAck: PropTypes.func.isRequired,

        // Callsigns and trigger fields of flights to alert; from Redux
        fullAlertFlightList: PropTypes.arrayOf(PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.object]))).isRequired,

        // Flags to indicate which alerts are triggerable; from Redux
        showCoordination: PropTypes.bool.isRequired,
        playCoordination: PropTypes.bool.isRequired,
        showScratchPad: PropTypes.bool.isRequired,
        playScratchPad: PropTypes.bool.isRequired,
        showFiltered: PropTypes.bool.isRequired,
        playFiltered: PropTypes.bool.isRequired,

        // Action creator to add flights to the Show Me table; from Redux
        addShowMeFlights: PropTypes.func.isRequired,

        // Current Show Me alert flight gufis; from Redux
        alertGufis: PropTypes.arrayOf(PropTypes.string),
    };

    /**
     * Constructs the FlightAlertModal class. This is used to alert the user to
     * flights of interest based on coordination status, scratch pad,
     * or user-defined filters.
     *
     * @param {*}    props
     * @param {func} props.onAck   function to call to acknolwedge flights
     */
    constructor(props)
    {
        super(props);
        this.coordinationSound = new Audio(coordinationSoundFile);
        this.filterFlightSound = new Audio(filterFlightSoundFile);
        this.scratchPadSound = new Audio(scratchPadSoundFile);
        this.state = {
            coordFlightList: [],
            filterFlightList: [],
            scratchFlightList: [],
        };
        this.getCoordinationColor = this.getCoordinationColor.bind(this);
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen.
     */
    clearAlert = () => {
        let allFlights = this.state.coordFlightList.concat(
            this.state.filterFlightList).concat(
            this.state.scratchFlightList);
        this.props.onAck(allFlights);
        this.setState({
            coordFlightList: [],
            filterFlightList: [],
            scratchFlightList: [],
        });
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen and
     * adds the alerted flights to the list for the Show Me table.
     */
    showFlight = () => {
        const allFlights = this.state.coordFlightList.concat(
            this.state.filterFlightList).concat(
            this.state.scratchFlightList);
        const allGufis = allFlights.map(alertRec => alertRec.gufi);
        if (this.props.alertGufis && this.props.alertGufis.length)
        {
            const newGufis = allGufis.filter(alertGufi => 
                !this.props.alertGufis.includes(alertGufi));
            if (newGufis.length)
            {
                this.props.addShowMeFlights(newGufis);
            }
        }
        else
        {
            this.props.addShowMeFlights(allGufis);
        }

        this.props.onAck(allFlights);
        this.setState({
            coordFlightList: [],
            filterFlightList: [],
            scratchFlightList: [],
        });
    }

    /**
     * Plays the defined sound to get the users attention for the coordination
     * (submission/approval) alert.
     */
    playCoordinationSound = () => {
        if (navigator.userActivation.hasBeenActive) {
            this.coordinationSound.play();
        }
    }

    /**
     * Plays the defined sound to get the users attention for the filtered
     * flight alert.
     */
    playFilterFlightSound = () => {
        if (navigator.userActivation.hasBeenActive) {
            this.filterFlightSound.play();
        }
    }

    /**
     * Plays the defined sound to get the users attention for the scratch pad
     * alert.
     */
    playScratchPadSound = () => {
        if (navigator.userActivation.hasBeenActive) {
            this.scratchPadSound.play();
        }
    }

    /**
     * Gets the color to display the coordination status.  The color is
     * defined from the color alerts.
     *
     * @param {string} coordinationStatus   a flight's coordination status
     *
     * @return {string} the css-friendly ("rgb(r,g,b") color of the 
     *         coordination status
     * @return {object} an object containing the foreground and background color
     *                      "{textColor: <textColor>, 
     *                       backgroundColor: <backgroundColor>}"
     *                  where the colors are formatted as a css-friendly string:
     *                  "rgb(r:<red>,g:<green>,b:<blue>)"
     */
    getCoordinationColor = (coordinationStatus) => {
        let colorSpec = getColorForEnum(coordinationStatus, 
            COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN.key);

        if (!colorSpec)
        {
            colorSpec = {textColor:BLACK_CSS, backgroundColor:WHITE_CSS};
        }

        return colorSpec;
    }

    /**
     * If this component did update, then make the appropriate changes to
     * the lists and check for playing the sound.
     *
     * @param {object} prevProps   the props object before the update
     */
    componentDidUpdate(prevProps)
    {
        // only make updates if properties changed, so we don't loop when
        // setting state.
        if (this.props !== prevProps)
        {
            let newCoordFlightList = [];
            let newFilterFlightList = [];
            let newScratchFlightList = [];

            // Find flights to alert based on coordination status, reroute requested has it's own modal
            if (this.props.showCoordination || this.props.playCoordination)
            {
                newCoordFlightList =
                    this.props.fullAlertFlightList.filter((flight) => {
                        return ((flight.type === ALERT_TYPE_COORDINATION) && (flight.coordinationStatus !== CoordinationState.REROUTE_REQUESTED.name));
                });
            }

            // Find flights to alert based on filter
            if (this.props.showFiltered || this.props.playFiltered)
            {
                newFilterFlightList =
                    this.props.fullAlertFlightList.filter((flight) => {
                        return (flight.type === ALERT_TYPE_FILTER);
                    });
            }

            // Find flights to alert based on scratch pad
            if (this.props.showScratchPad || this.props.playScratchPad)
            {
                newScratchFlightList =
                    this.props.fullAlertFlightList.filter((flight) => {
                        return (flight.type === ALERT_TYPE_SCRATCH_PAD);
                });
            }

            // Play the alerts
            if (this.props.playCoordination &&
                !this.state.coordFlightList.length &&
                newCoordFlightList.length)
            {
                this.playCoordinationSound();
                console.log("Playing Coordination alert",
                    newCoordFlightList);
            }
            if (this.props.playFiltered &&
                !this.state.filterFlightList.length &&
                newFilterFlightList.length)
            {
                this.playFilterFlightSound();
                console.log("Playing Filter Flight alert",
                    newFilterFlightList);
            }
            if (this.props.playScratchPad &&
               !this.state.scratchFlightList.length &&
                newScratchFlightList.length)
            {
                this.playScratchPadSound();
                console.log("Playing Scratch Pad alert",
                    newScratchFlightList);
            }

            // Save the alerted flights
            this.setState({
                coordFlightList: newCoordFlightList,
                filterFlightList: newFilterFlightList,
                scratchFlightList: newScratchFlightList,
            });
        }
    }

    /**
     * Displays the ReactModal with our options and content. It will actually
     * only be open and shown if there are flights in the list to show.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        let showCoord = this.props.showCoordination &&
                       (this.state.coordFlightList.length > 0);
        let showFilter = this.props.showFiltered &&
                        (this.state.filterFlightList.length > 0);
        let showScratch = this.props.showScratchPad &&
                        (this.state.scratchFlightList.length > 0);
        let showModal = showCoord || showFilter || showScratch;

        if (showModal)
        {
            this.flashTab(showScratch ? "ScratchPad Updated" :
                showFilter ? "Flight Filter Alert" : "Approval Alert");
            return (
                <Modal show={true} onHide={this.clearAlert} scrollable>
                    <Modal.Header>
                        <Modal.Title>TOS Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      { showCoord &&
                        <ListGroup variant="flush" id="coordAlerts">
                            { this.state.coordFlightList.map(flight =>
                                {
                                    const colorSpec = this.getCoordinationColor(
                                        flight.coordinationStatus);
                                    return (
                                        <ListGroup.Item key={flight.acid}
                                            style={{color: colorSpec.textColor,
                                                backgroundColor:
                                                    colorSpec.backgroundColor}}
                                        >
                                            {flight.acid}&nbsp;-&nbsp;
                                            {CoordinationState[
                                                flight.coordinationStatus].display}
                                        </ListGroup.Item>
                                    );
                                })
                            }
                        </ListGroup>
                      }
                      { showFilter &&
                        <ListGroup variant="flush" id="filterFlightAlerts">
                            { this.state.filterFlightList.map((flight) =>
                                <ListGroup.Item key={flight.acid}
                                    className="filter-flight-alert--color"
                                >
                                    {flight.acid} - {flight.reason}
                                </ListGroup.Item>
                              )
                            }
                        </ListGroup>
                      }
                      { showScratch &&
                        <ListGroup variant="flush" id="scratchPadAlerts">
                            { this.state.scratchFlightList.map((flight) =>
                                <ListGroup.Item key={flight.acid}
                                        className="scratch-pad-alert--color"
                                >
                                    {flight.acid} - Scratch Pad
                                </ListGroup.Item>
                              )
                            }
                        </ListGroup>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showFlight}>
                            Show
                        </Button>
                        <Button variant="secondary" onClick={this.clearAlert}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            this.endFlash();
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state  The current Redux state
 *
 * @return {object} The desired Redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        fullAlertFlightList : state.modalReducer.alertFlights,
        showCoordination : state.modalReducer.alertTypes.coordinationVisual,
        playCoordination : state.modalReducer.alertTypes.coordinationAudible,
        showFiltered : state.modalReducer.alertTypes.filteredVisual,
        playFiltered : state.modalReducer.alertTypes.filteredAudible,
        showScratchPad : state.modalReducer.alertTypes.scratchPadVisual,
        playScratchPad : state.modalReducer.alertTypes.scratchPadAudible,
        alertGufis: state.showMeReducer.alertGufis,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addShowMeFlights,
};

export default connect(mapStateToProps, mapDispatchToProps) (FlightAlertModal);
