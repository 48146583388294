import { store } from "./store";
import { CoordinationState, EligibilityState } from "../constants/TosEnum";

/**
 * This file contains utility functions involved in user actions, mostly to
 * determine which actions are currently available.
 */
export const actionUtils = {
    hasAtcAuthority,
    enableApprove,
    enableUnapprove,
    enableUnable,
    enableUnunable,
    enableSubmit,
    enableUnsubmit,
    enableOperatorExcluded,
    enableOperatorUnexcluded,
};

/**
 * Determines if ATC has authority to perform a flight action.
 *
 * @param {string} role     user role
 * @param {string} origin   flight origin to determine approver
 *
 * @return {boolean} true if ATC has approval authority for the action
 */
function hasAtcAuthority(role, origin)
{
    return (role === "ATC_" + getApprovers()[origin]);
}

/**
 * Gets the TOS approver map from Redux.
 *
 * @return {object} TOS approvers; the property names are airports, values
 *                  are the approver (TOWER or CENTER)
 */
function getApprovers()
{
    return store.getState().dataReducer.tosStatus.approvalTypes;
}

/**
 * Determines if a route option is allowed to be Approved.  This method assumes
 * hasAtcAuthority() is called in conjunction with this method.
 *
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if the option can be Approved
 */
function enableApprove(flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.REROUTE_REQUESTED.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_ACTION.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_EXCLUDE.name) &&
           (flightCoordStatus !== CoordinationState.ATC_APPROVED.name) &&
           (flightCoordStatus !== CoordinationState.REROUTE_FILED.name);
}

/**
 * Determines if a route option is allowed to have an approval undone.
 * This method assumes hasAtcAuthority() is called in conjunction with this
 * method.
 *
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if the option can be unapproved
 */
function enableUnapprove(flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.ATC_APPROVED.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_ACTION.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_EXCLUDE.name) &&
           (flightCoordStatus !== CoordinationState.REROUTE_FILED.name);
}

/**
 * Determines if a route option is allowed to be marked Unable.  This method
 * assumes hasAtcAuthority() is called in conjunction with this method.
 *
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if the option can be marked Unable
 */
function enableUnable(flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.REROUTE_REQUESTED.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_ACTION.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_EXCLUDE.name) &&
           (flightCoordStatus !== CoordinationState.ATC_APPROVED.name) &&
           (flightCoordStatus !== CoordinationState.REROUTE_FILED.name);
}

/**
 * Determines if a route option is allowed to have unable undone.  This method
 * assumes hasAtcAuthority() is called in conjunction with this method.
 *
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if the option can be Ununabled
 */
function enableUnunable(flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.ATC_UNABLE.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_ACTION.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_EXCLUDE.name) &&
           (flightCoordStatus !== CoordinationState.REROUTE_FILED.name);
}

/**
 * Determines if a route option is allowed to be Submitted based on the
 * flight and route coordination state.
 *
 * @param {string} flightEligStatus   flight eligibility status
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if statuses would allow submitting this option
 */
function enableSubmit(flightEligStatus, flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.DEFAULT.name) &&
        ((flightEligStatus === EligibilityState.POTENTIAL.name) ||
            (flightEligStatus === EligibilityState.CANDIDATE.name)) &&
        ((flightCoordStatus === CoordinationState.DEFAULT.name) ||
            (flightCoordStatus === CoordinationState.REROUTE_REQUESTED.name) ||
            (flightCoordStatus === CoordinationState.ATC_UNABLE.name));
}

/**
 * Determines if a route option is allowed to have Submit undone.
 *
 * @param {string} flightCoordStatus  flight coordination status
 * @param {string} routeCoordStatus   route option coordination status
 *
 * @return {boolean} true if the status would allow unsubmitting this option
 */
function enableUnsubmit(flightCoordStatus, routeCoordStatus)
{
    return (routeCoordStatus === CoordinationState.REROUTE_REQUESTED.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_ACTION.name) &&
           (flightCoordStatus !== CoordinationState.PENDING_EXCLUDE.name);
}

/**
 * Determines based on flight level parameters if a flight is allowed to be
 * excluded by an operator.
 *
 * @param {string} flightEligStatus   flight eligibility status
 * @param {string} flightCoordStatus  flight coordination status
 *
 * @return {boolean} true if the flight operator can exclude the flight
 */
function enableOperatorExcluded(flightEligStatus, flightCoordStatus)
{
    return (flightEligStatus !== EligibilityState.EXPIRED.name) &&
           (flightEligStatus !== EligibilityState.EXCLUDED.name) &&
           ((flightCoordStatus === CoordinationState.DEFAULT.name) ||
                (flightCoordStatus === CoordinationState.ATC_UNABLE.name));
}

/**
 * Determines based on flight level parameters if a flight is allowed to be
 * undo-excluded by an operator.
 *
 * @param {string} flightEligStatus   flight eligibility status
 * @param {string} flightCoordStatus  flight coordination status
 *
 * @return {boolean} true if the flight operator can undo the exclusion
 */
function enableOperatorUnexcluded(flightEligStatus, flightCoordStatus)
{
    return (flightEligStatus === EligibilityState.EXCLUDED.name) &&
           ((flightCoordStatus === CoordinationState.OPERATOR_EXCLUDED.name) ||
                (flightCoordStatus === CoordinationState.ATC_UNABLE.name));
}
